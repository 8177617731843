import { CircularProgress, Stack } from '@mui/material';

const CustomLoading = ({ size = '2rem' }) => {
  return (
    <Stack
      sx={{ justifyContent: 'center', padding: '10px' }}
      spacing={2}
      direction='row'
    >
      <CircularProgress size={size} />
    </Stack>
  );
};

export default CustomLoading;
