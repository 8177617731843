const EditIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_0_18795)">
        <rect width="24" height="24" fill="white" />
        <path d="M17.7895 20H6.22211C5.63277 20 5.06757 19.7659 4.65084 19.3492C4.23411 18.9324 4 18.3672 4 17.7779V6.22291C4 5.63357 4.23411 5.06836 4.65084 4.65164C5.06757 4.23491 5.63277 4.00079 6.22211 4.00079H12.4565C12.5743 4.00079 12.6874 4.04762 12.7707 4.13096C12.8541 4.21431 12.9009 4.32735 12.9009 4.44522C12.9009 4.56308 12.8541 4.67613 12.7707 4.75947C12.6874 4.84282 12.5743 4.88964 12.4565 4.88964H6.22211C5.86851 4.88964 5.52939 5.03011 5.27935 5.28014C5.02931 5.53018 4.88884 5.8693 4.88884 6.22291V17.7779C4.88884 18.1315 5.02931 18.4706 5.27935 18.7206C5.52939 18.9707 5.86851 19.1112 6.22211 19.1112H17.7895C18.1431 19.1112 18.4823 18.9707 18.7323 18.7206C18.9823 18.4706 19.1228 18.1315 19.1228 17.7779V11.556C19.1228 11.4381 19.1696 11.3251 19.253 11.2417C19.3363 11.1584 19.4494 11.1116 19.5672 11.1116C19.6851 11.1116 19.7981 11.1584 19.8815 11.2417C19.9648 11.3251 20.0116 11.4381 20.0116 11.556V17.7779C20.0116 18.3672 19.7775 18.9324 19.3608 19.3492C18.9441 19.7659 18.3789 20 17.7895 20ZM12.4565 13.7781H10.6788C10.5609 13.7781 10.4479 13.7313 10.3645 13.6479C10.2812 13.5646 10.2344 13.4515 10.2344 13.3337V11.556C10.2344 11.4975 10.2459 11.4396 10.2683 11.3856C10.2908 11.3316 10.3236 11.2826 10.365 11.2413L17.4758 4.13057C17.5171 4.08918 17.5661 4.05634 17.6201 4.03394C17.6741 4.01153 17.732 4 17.7904 4C17.8489 4 17.9068 4.01153 17.9608 4.03394C18.0148 4.05634 18.0638 4.08918 18.1051 4.13057L19.8828 5.90826C19.9242 5.94954 19.957 5.99858 19.9794 6.05257C20.0018 6.10657 20.0133 6.16445 20.0133 6.22291C20.0133 6.28136 20.0018 6.33925 19.9794 6.39324C19.957 6.44723 19.9242 6.49627 19.8828 6.53756L12.772 13.6483C12.7306 13.6897 12.6813 13.7224 12.6272 13.7447C12.573 13.767 12.515 13.7783 12.4565 13.7781ZM17.7895 5.07363L11.1232 11.74V12.8892H12.2725L18.9388 6.22291L17.7895 5.07452V5.07363Z" fill="#18A0FB" />
      </g>
      <defs>
        <clipPath id="clip0_0_18795">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditIcon;
